var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-view-container"},[_c('div',{staticClass:"user-info"},[_vm._m(0),_c('div',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.phoneNumber))])]),_c('div',{staticClass:"option-item",on:{"click":function($event){return _vm.showUserInfo()}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"option-item",on:{"click":function($event){return _vm.showMyBills()}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"option-item",on:{"click":function($event){return _vm.showPatientList()}}},[_vm._m(5),_vm._m(6)]),_c('div',{ref:"popViewContainer",staticClass:"popup-view-container hide"}),(_vm.userInfoViewVisible)?_c('div',{staticClass:"user-info-view"}):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-icon"},[_c('i',{staticClass:"el-icon-user"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-info"},[_c('div',{staticClass:"option-icon"},[_c('i',{staticClass:"el-icon-user"})]),_c('div',{staticClass:"option-name"},[_vm._v("个人信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-icon"},[_c('div',{staticClass:"arrow-icon"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-info"},[_c('div',{staticClass:"option-icon"},[_c('i',{staticClass:"el-icon-tickets"})]),_c('div',{staticClass:"option-name"},[_vm._v("我的账单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-icon"},[_c('div',{staticClass:"arrow-icon"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-info"},[_c('div',{staticClass:"option-icon"},[_c('i',{staticClass:"el-icon-s-custom"})]),_c('div',{staticClass:"option-name"},[_vm._v("关心的人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-icon"},[_c('div',{staticClass:"arrow-icon"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
}]

export { render, staticRenderFns }