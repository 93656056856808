<template>
    <div class="user-view-container">
        <div class="user-info">
            <div class="user-icon"><i class="el-icon-user"></i></div>
            <div class="user-name">{{phoneNumber}}</div>
        </div>
        <div class="option-item" @click="showUserInfo()">
            <div class="option-info">
                <div class="option-icon"><i class="el-icon-user"></i></div>
                <div class="option-name">个人信息</div>
            </div>
            <div class="next-icon">
                <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
            </div>
        </div>
        <div class="option-item" @click="showMyBills()">
            <div class="option-info">
                <div class="option-icon"><i class="el-icon-tickets"></i></div>
                <div class="option-name">我的账单</div>
            </div>
            <div class="next-icon">
                <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
            </div>
        </div>
        <div class="option-item" @click="showPatientList()">
            <div class="option-info">
                <div class="option-icon"><i class="el-icon-s-custom"></i></div>
                <div class="option-name">关心的人</div>
            </div>
            <div class="next-icon">
                <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
            </div>
        </div>
        <div class="popup-view-container hide" ref="popViewContainer">
            <!-- <PatientListView v-on:closeView="closePopupView"/> -->
        </div>
        <div class="user-info-view" v-if="userInfoViewVisible">
            <!-- <UserInfoBrowseView v-on:closeView="closeUserInfoView" /> -->
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import { store } from '../../utils/store'
// import PatientListView from './PatientListView.vue'
// import UserInfoBrowseView from './UserInfoBrowseView.vue'

export default {
    name: 'UserView',
    components: {
        // PatientListView,
        // UserInfoBrowseView
    },
    // props: {
    //     userId: ""
    // },
    data() {
        return {
            // phoneNumber: store.phoneNumber
            userInfoViewVisible: false
        }
    },
    computed: {
        phoneNumber: function() {
            return store.phoneNumber;
        }
    },
    // created() {
    //     this.phoneNumber = store.phoneNumber;
    // },
    // updated() {
    //     this.phoneNumber = store.phoneNumber;
    // },
    // mounted() {
    //     this.phoneNumber = store.phoneNumber;
    // },
    methods: {
        showPatientList() {
            // let elPopViewContainer = this.$refs.popViewContainer;
            // elPopViewContainer.classList.remove('hide');
            this.$router.push('user/concern');
        },
        closePopupView() {
            let elPopViewContainer = this.$refs.popViewContainer;
            elPopViewContainer.classList.add('hide');
        },
        showUserInfo() {
            // this.userInfoViewVisible = true;
            this.$router.push('user/info');
        },
        closeUserInfoView() {
            this.userInfoViewVisible = false;
        },
        showMyBills() {
            this.$message('正在开发中...')
        }
    }
}
</script>

<style scoped>
    .user-view-container {
        height: 100%;
        overflow-y: auto;
    }
    .user-info {
        position: relative;
        background-color: #fff;
        color: #222;
        margin: 0 0 2vmin 0;
        padding: 3vmin;
        text-align: left;
        font-size: 3.5vmin;
    }
    .user-info .user-icon {
        font-size: 10vmin;
        display: inline-block;
    }
    .user-info .user-name {
        font-size: 3.5vmin;
        display: inline-block;
        margin-left: 3vmin;
    }

    .option-item {
        position: relative;
        background-color: #fff;
        color: #222;
        margin: 0 0 2vmin 0;
        padding: 3vmin;
        text-align: left;
        font-size: 4vmin;

        display: flex;
        align-items: stretch;
    }
    .option-item:active {
        background-color: #eaeaea;
    }
    .option-item .option-info {
        flex-grow: 1;
    }
    .option-item .next-icon {
        font-size: 4vmin;
        color: #ccc;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .option-item .option-icon {
        font-size: 5vmin;
        display: inline-block;
    }
    .option-item .option-name {
        font-size: 4vmin;
        display: inline-block;
        margin-left: 5vmin;
    }

    .popup-view-container,
    .user-info-view {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 2;
    }
    .popup-view-container.hide {
        display: none;
    }
    
</style>